import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import * as React from "react";
import { useEffect, useState } from "react";
import LifeTime from "./LifeTime";

dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

function useDate(fun: () => any): any {
  const [state, setState] = useState(fun());
  useEffect(() => {
    const timerId = setTimeout(() => {
      setState(fun());
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [fun]);
  return state;
}

export function App(): JSX.Element {
  const now = useDate(() => dayjs());

  const second = now.second();
  const minutes = now.minute();
  const hours = now.hour();
  const dayInMonth = now.date();
  const daysInMonth = now.daysInMonth();
  const dayInWeek = now.isoWeekday();
  const months = now.month();
  const weeksOfTheYear = now.isoWeek();
  const weeksInTheYear = now.isoWeeksInYear();
  const yearsOfDecade = now.year() % 10;
  const yearsOfCentury = now.year() % 100;
  const yearsInMillennium = now.year() % 1000;
  const age = now.diff("1981-05-02", "year");

  return (
    <div className="app">
      <h1 className="heading">Tempus Fugit</h1>
      <div>
        <LifeTime available={60} used={second} of={"Seconds in the minute"} />
        <LifeTime available={60} used={minutes} of={"Minutes in the hour"} />
        <LifeTime available={24} used={hours} of={"Hours in the day"} />
        <LifeTime available={7} used={dayInWeek} of={"Days in the week"} />
        <LifeTime
          available={daysInMonth}
          used={dayInMonth}
          of={"Days of the month"}
        />
        <LifeTime
          available={weeksInTheYear}
          used={weeksOfTheYear}
          of={"Weeks of the year"}
        />
        <LifeTime available={12} used={months + 1} of={"Months of the year"} />
        <LifeTime
          available={10}
          used={yearsOfDecade}
          of={"Years in the decade"}
        />
        <LifeTime
          available={100}
          used={yearsOfCentury}
          of={"Years in the century"}
        />
        <LifeTime available={110} used={age} of={"Years of age"} />
        <LifeTime
          available={1000}
          used={yearsInMillennium}
          of={"Years in the millennium"}
        />
        <LifeTime
          available={10000000000}
          used={4543000000}
          of={"Years of our earth"}
        />
        <LifeTime
          available={10000000000}
          used={4571000000}
          of={"Years of the solar-system"}
        />
        <LifeTime
          available={200000000000}
          used={14000000000}
          of={"Years of the universe"}
        />
      </div>
    </div>
  );
}
